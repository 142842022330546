/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect } from "react"
import { useState } from "react"
import { Table, Tr, Td } from "../components/Table/TableElements"
import ImageCardSmall from "../components/Elements/ImageCardSmall"
import Layout from "../components/Layout/Pages"
import { navigate } from "gatsby"
import Tag from "../components/Elements/Tag"

import Button from "../components/Elements/Button"

import CartContext from "../context/Cart/CartContext"
import BannerSimple from "../components/Commons/BannerSimple"
import Container from "../components/Container"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const OrderConfirmation = ({ location }) => {
  // const [selectedColor, setSelectedColor] = useState(product.colors[0])
  const {
    cart,
    orderConfirmation,
    prevCart,
    userAddresses,
    setOrderConfirmation,
    handleRemoveClick,
  } = useContext(CartContext)

  // const { } = useContext
  // console.log("TEST", orderConfirmation, prevCart)

  useEffect(() => {
    return () => {
      setOrderConfirmation(false)
    }
  }, [])

  const [orderedAddresses, setOrderedAddresses] = useState([])
  useEffect(() => {
    const ads = new Set()
    prevCart.map((v, k) => {
      v.addresses.map(v => {
        ads.add(parseInt(v.address_id))
      })
    })
    setOrderedAddresses([...ads])
  }, [prevCart])
  return (
    <Layout metaTitle={"Order Confirmation"} thin>
      <BannerSimple heading={"Order Confirmation"} />
      <Container>
        {orderConfirmation === null && (
          <>
            {userAddresses
              .filter(c => orderedAddresses.includes(c.id))
              .map((order, k) => {
                console.log(k)
                return (
                  <div className="mb-[30px]">
                    <div className="flex items-center mb-4">
                      <h3 className="text-med-18 uppercase flex items-center">
                        Order: #{k + 1}
                      </h3>
                      <div className="ml-3">
                        <Tag>Processing</Tag>
                      </div>
                    </div>
                    <div className="flex mb-5">
                      <p className="mr-4 text-reg-14">Shipping to:</p>
                      <p className="text-reg-14">
                        <>
                          <b>{`${order.name},`}</b>
                          <br />
                        </>
                        {order.location.split(",").map((e, i) =>
                          i == 2 ? (
                            <>
                              {`${e},`}
                              <br />
                            </>
                          ) : (
                            `${
                              order.location.split(",").length == i + 1
                                ? e
                                : `${e}, `
                            }`
                          )
                        )}
                        <br />
                      </p>
                      {/*  <div className="flex md:ml-auto">
                      <Button pink onClick={() => {}}>
                        View
                      </Button>
                    </div> */}
                    </div>
                    {prevCart.length > 0 && (
                      <>
                        <Table columns={["Asset", "SKU", "Quantity"]}>
                          {prevCart
                            .filter(c =>
                              c.addresses
                                .map(v => parseInt(v.address_id))
                                .includes(order.id)
                            )
                            .map((item, k) => {
                              // let quantity = orderConfirmation.filter(
                              //   f => f.id === order.id
                              // )[0]
                              // quantity = quantity.line_items.filter(f =>
                              //   f.properties.filter(
                              //     ff =>
                              //       ff.name === "_AssetID" &&
                              //       parseInt(ff.value) === parseInt(item.asset_id)
                              //   )
                              // )[0].quantity

                              console.log(item)
                              console.log("item")
                              // let orderDetails = location.state.orderConfirmation.map()
                              return (
                                <Tr key={k}>
                                  <Td>
                                    <div className="flex items-center">
                                      <ImageCardSmall
                                        image={item.asset_image}
                                        alt=""
                                        size={70}
                                      />
                                      <div className="ml-6 text-black">
                                        {item.asset_title}
                                      </div>
                                    </div>
                                  </Td>
                                  <Td>{item.asset_sku}</Td>
                                  <Td>
                                    {
                                      item.addresses.filter(
                                        v => parseInt(v.address_id) == order.id
                                      )[0].quantity
                                    }
                                  </Td>
                                </Tr>
                              )
                            })}
                        </Table>
                      </>
                    )}
                  </div>
                )
              })}
          </>
        )}
        {orderConfirmation && (
          <>
            {/* {orderConfirmation > 1 && (
              <p>
                We've split your requested assets into{" "}
                {orderConfirmation.length} separate orders, one for each store.
              </p>
            )} */}

            {orderConfirmation[0]
              .filter(v => Boolean(v))
              .map((order, k) => {
                return (
                  <div className="mb-[30px]">
                    <div className="flex items-center mb-4">
                      <h3 className="text-med-18 uppercase">
                        Order: #{order.order_id || k + 1}
                      </h3>
                      <div className="ml-3">
                        <Tag>Processing</Tag>
                      </div>
                    </div>
                    <div className="flex mb-5">
                      <p className="mr-4 text-reg-14">Shipping to:</p>
                      <p className="text-reg-14">
                        {order.shipping_address.company !== null && (
                          <>
                            <b>{`${order.shipping_address.company},`}</b>
                            <br />
                          </>
                        )}
                        {order.shipping_address.address1}
                        {order.shipping_address.address2 !== "" &&
                          `, ${order.shipping_address.address2}`}
                        {order.shipping_address.city !== "" &&
                          `, ${order.shipping_address.city}`}
                        <br />
                        {order.shipping_address.province}
                        {order.shipping_address.zip !== "" &&
                          `, ${order.shipping_address.zip}`}
                        {order.shipping_address.country !== "" &&
                          `, ${order.shipping_address.country}`}
                      </p>
                      {/*  <div className="flex md:ml-auto">
                      <Button pink onClick={() => {}}>
                        View
                      </Button>
                    </div> */}
                    </div>
                    {prevCart.length > 0 && (
                      <>
                        <Table columns={["Asset", "SKU", "Quantity"]}>
                          {prevCart
                            .filter(f =>
                              order.line_items
                                .map(
                                  e =>
                                    e.properties.find(
                                      g => g.name === "_AssetID"
                                    ).value
                                )
                                .includes(f.asset_id)
                            )
                            .map((item, k) => {
                              // let quantity =

                              let quantity = order.line_items.find(
                                f =>
                                  parseInt(f.properties[0].value) ===
                                  parseInt(item.asset_id)
                              ).quantity

                              // let quantity = orderConfirmation[0].filter(
                              //   f => f.id === order.id
                              // )[0]
                              // console.log(quantity, "quantity")

                              // quantity = quantity.line_items.filter(f =>
                              //   f.properties.filter(
                              //     ff =>
                              //       ff.name === "_AssetID" &&
                              //       parseInt(ff.value) === parseInt(item.asset_id)
                              //   )
                              // )[0].quantity
                              // console.log(quantity, "quantity")

                              // console.log(item)
                              // console.log("item")
                              // let orderDetails = location.state.orderConfirmation.map()
                              return (
                                <Tr key={k}>
                                  <Td>
                                    <div className="flex items-center">
                                      <ImageCardSmall
                                        image={item.asset_image}
                                        alt=""
                                        size={70}
                                      />
                                      <div className="ml-6 text-black">
                                        {item.asset_title}
                                      </div>
                                    </div>
                                  </Td>
                                  <Td>{item.asset_sku}</Td>
                                  <Td>{quantity}</Td>
                                </Tr>
                              )
                            })}
                        </Table>
                      </>
                    )}
                  </div>
                )
              })}
            {Object.keys(orderConfirmation.tp).length > 0 &&
              Object.values(orderConfirmation?.tp).map((oInfo, k) => {
                // console.log(oInfo, k, "oInfo")
                let order = oInfo[0]
                let address = userAddresses.filter(
                  c => c.id === order.address_id
                )[0]
                // console.log('234')
                // console.log(address, userAddresses, order, prevCart)
                return (
                  <div className="mb-[30px]">
                    <div className="flex items-center mb-4">
                      <h3 className="text-med-18 uppercase">
                        Order: #{order.id || k + 1}
                      </h3>
                      <div className="ml-3">
                        <Tag>Processing</Tag>
                      </div>
                    </div>
                    <div className="flex mb-5">
                      <p className="mr-4 text-reg-14">Shipping to:</p>
                      <p className="text-reg-14">
                        <>
                          <b>{`${address?.name},`}</b>
                          <br />
                        </>
                        {address.location.split(",").map((e, i) =>
                          i == 2 ? (
                            <>
                              {`${e},`}
                              <br />
                            </>
                          ) : (
                            `${
                              address.location.split(",").length == i + 1
                                ? e
                                : `${e}, `
                            }`
                          )
                        )}
                        <br />
                      </p>
                      {/*  <div className="flex md:ml-auto">
                      <Button pink onClick={() => {}}>
                        View
                      </Button>
                    </div> */}
                    </div>
                    {prevCart.length > 0 && (
                      <>
                        <Table columns={["Asset", "SKU", "Quantity"]}>
                          {prevCart
                            .filter(c => order.assets.includes(`${c.asset_id}`))
                            .map((item, k) => {
                              // let quantity = orderConfirmation.filter(
                              //   f => f.id === order.id
                              // )[0]
                              // quantity = quantity.line_items.filter(f =>
                              //   f.properties.filter(
                              //     ff =>
                              //       ff.name === "_AssetID" &&
                              //       parseInt(ff.value) === parseInt(item.asset_id)
                              //   )
                              // )[0].quantity

                              // console.log(item)
                              // console.log("item")
                              // let orderDetails = location.state.orderConfirmation.map()
                              return (
                                <Tr key={k}>
                                  <Td>
                                    <div className="flex items-center">
                                      <ImageCardSmall
                                        image={item.asset_image}
                                        alt=""
                                        size={70}
                                      />
                                      <div className="ml-6 text-black">
                                        {item.asset_title}
                                      </div>
                                    </div>
                                  </Td>
                                  <Td>{item.asset_sku}</Td>
                                  <Td>
                                    {
                                      item.addresses.filter(
                                        v =>
                                          parseInt(v.address_id) ==
                                          order.address_id
                                      )[0].quantity
                                    }
                                  </Td>
                                </Tr>
                              )
                            })}{" "}
                        </Table>
                      </>
                    )}
                  </div>
                )
              })}
          </>
        )}

        {orderConfirmation === false && (
          <p className="text-reg-16">
            This page has expired. Please access your order details via the
            dashboard.
          </p>
        )}
        <div className="lg:mx-auto my-[30px] lg:w-[500px] lg:text-center">
          <p className="text-reg-16 mb-[30px]">Thank you for using O2O.</p>
          <div className="lg:mx-auto flex lg:w-[500px] items-stretch space-x-[30px]">
            <Button full onClick={() => navigate("/dashboard/my-orders")} red>
              Past Orders
            </Button>
            <Button full onClick={() => navigate("/")} pink>
              Back Home
            </Button>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
// export async function getServerData(context) {
//   // console.log(context)
//   // let id = 1

//   try {
//     const token = await sessToken()
//     const res = await getCart(token)
//     console.log(res)
//     if (!res) {
//       return {
//         props: false,
//       }
//     }
//     return {
//       props: res,
//     }
//   } catch (error) {
//     return {
//       status: 500,
//       headers: {},
//       props: {},
//     }
//   }
// }
export default OrderConfirmation
